import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  RU: {
    translation: {
      BykovArtem: 'Быков Артём',
      About: 'Обо мне',
      Skills: 'Навыки',
      Experience: 'Опыт работы',
      Competence: 'Компетенции',
      Portfolio: 'Портфолио',
      Contact: 'Контакты',
      'My strengths':
        'Сильные стороны: тайм менеджмент, ответственность и решение нестандартных задач',
      'These are the qualities ...':
        'Те качества, которые отмечал каждый руководитель при работе со мной',
      Now: 'Сейчас',
      Value: 'Ценности',
      Future: 'В будущем',
      'At free time': 'В свободное время',
      'I live in Spain ...':
        'Я проживаю в Испании и имею разрешение на работу. В настоящее время ищу возможности для удаленной работы в Европе и с перспективой профессионального развития',
      'In work, I appreciate ...':
        'В профессиональной деятельности я ценю структурированность, эффективную коммуникацию и четкую организацию рабочего процесса, где каждый участник команды имеет ясное представление о своих задачах и ответственности',
      'I will continue to deepen my knowledge ...':
        'Я планирую дальше развивать свои компетенции в области фронтенд-разработки и активно участвовать в создании уникальных продуктов. Моя цель — найти такую работу, где я смогу не только совершенствовать свои умения, но и вносить значимый вклад в успех команды',
      'I like to travel, swim ...':
        'Люблю путешествия, плавание, просмотр фильмов с захватывающими сюжетами, общение с друзьями и разгадывание головоломок',
      'Download CV': 'Скачать резюме',
      'Main tasks': 'Основные задачи',
      'My works results': 'Результаты работы',
      MGCom: 'MGCom, Россия, Москва',
      'MGCom experience': 'Август 2021 — Настоящий момент (2,5+ года)',
      'MGCom_main-1':
        'Адаптивная и кроссбраузерная вёрстка лендингов и страниц сайта',
      'MGCom_main-2': 'Оптимизация скорости загрузки сайта',
      'MGCom_main-3': 'Создание и изменение компонентов сайта',
      'MGCom_main-4': 'Создание и изменение сайтов на Tilda',
      'MGCom_main-5': 'Создание telegram ботов',
      'MGCom_result-1': 'Увеличил количество выигранных тендеров',
      'MGCom_result-2':
        'Помог открыть новые направление для привлечения клиентов',
      ADVIGEN: 'ADVIGEN Digital, Россия, \nСанкт–Петербург',
      'ADVIGEN experience': 'Март 2021 — Июль 2021 (5\u00A0месяцев)',
      'ADVIGEN_main-1':
        'Адаптивная и кроссбраузерная вёрстка лендингов и страниц сайта на CMS Bitrix',
      'ADVIGEN_main-2':
        'Создание и изменение вёрстки и компонентов сайта на CMS Bitrix',
      'ADVIGEN_main-3': 'Проверка сайтов по SEO критериям',
      'ADVIGEN_main-4': 'Настройка на сайтах аналитики',
      'ADVIGEN_result-1':
        'Создание чек-листа по проверке сайта на SEO оптимизацию, что позволило ускорить процесс проверки сайтов по стандартным критериям',
      AMBER: 'AMBER, Россия, Санкт-Петербург',
      'AMBER experience': 'Февраль 2019 — Март 2021 (2 года 1 месяц)',
      'AMBER_main-1':
        'Анализ мобильной версии сайта и составление рекомендаций по улучшению показателей',
      'AMBER_main-2': 'Настройка на сайтах аналитики',
      'AMBER_main-3':
        'Ведение проектов и распределение задач между другими сотрудниками',
      'AMBER_main-4': 'Общение с клиентами',
      'AMBER_result-1':
        'Быстро продвинулся от младшего до старшего специалиста',
      'AMBER_result-2': 'Брал под своё руководство младших специалистов',
      'View Case': 'Посмотреть кейс',
      'See website': 'Перейти на сайт',
      'See bot': 'Посмотреть бота',
      'Contact-description':
        'Если вы находитесь в поиске фронтенд-разработчика обращайтесь ко мне:',
      Phone: 'Телефон',
      'Design by': 'За дизайн спасибо',
      Stack: 'Стек',
      Tools: 'Инструменты',
      Feachers: 'Особенности',
      'Portfolio-1-title': '80 Level Cloud Experiences',
      'Portfolio-1-description': 'Лендинг для платформы 80 Level',
      'Portfolio-1-text':
        'Коммерческий проект. Лендинг для платформы 80 Level. Сама платформа разработана для создателей контента на Unreal Engine 5, где они могут выкладывать свои 3D-проекты.',
      'Portfolio-1-feachers-1':
        'Адаптивность для идеального отображения на разных устройствах',
      'Portfolio-1-feachers-2':
        'Кроссбраузерность для стабильной работы во всех основных браузерах',
      'Portfolio-1-feachers-3':
        'Высокая производительность благодаря оптимизации загрузки и изображений',
      'Portfolio-1-feachers-4':
        'Интеграция Metaframe в проект была осуществлена с помощью использования React и API клиента',

      'Portfolio-2-title': '80 Level Metasites Challenge',
      'Portfolio-2-description': 'Лендинг для платформы 80 Level',
      'Portfolio-2-text':
        'Коммерческий проект. Лендинг для платформы 80 Level, чтобы сборать заявки для конкурса. Сама платформа разработана для создателей контента на Unreal Engine 5, где они могут выкладывать свои 3D-проекты.',
      'Portfolio-2-feachers-1':
        'Адаптивность для идеального отображения на разных устройствах',
      'Portfolio-2-feachers-2':
        'Кроссбраузерность для стабильной работы во всех основных браузерах',
      'Portfolio-2-feachers-3':
        'Высокая производительность благодаря оптимизации загрузки и изображений',
      'Portfolio-2-feachers-4':
        'Интеграция Metaframe в проект была осуществлена с помощью использования React и API клиента',

      'Portfolio-3-title': 'Marvel Information Portal',
      'Portfolio-3-description':
        'Информационный портал по героям и комиксам Marvel',
      'Portfolio-3-text':
        'Pet-проект создавался для улучшения навыков React и изучения инструментов axios, MUI, styled-omponents, а также для углубленного изучения работы API',
      'Portfolio-3-feachers-1':
        'Во время получения данных с сервера отображается индикатор загрузки',
      'Portfolio-3-feachers-2':
        'Обработка некорректных ответов сервера с отображением соответствующих заглушек',
      'Portfolio-3-feachers-3':
        'Динамическая подгрузка контента при прокрутке страницы',

      'Portfolio-4-title': 'Wheather Map',
      'Portfolio-4-description':
        'Интерактивная карта погоды, позволяющая в реальном времени отслеживать погоду по всему миру',
      'Portfolio-4-text':
        'Pet-проект был разработан с целью усовершенствования навыков работы с React, изучения функциональных возможностей Mapbox и глубокого погружения в взаимодействие с API. Интерфейс Mapbox предоставляет возможность получения координат при клике на карту, а с использованием Weather API реализована функция отображения погоды в выбранной точке.',
      'Portfolio-4-feachers-1':
        'Во время получения данных с сервера отображается индикатор загрузки',

      'Portfolio-5-title': 'Отдых в Дубае',
      'Portfolio-5-description':
        'Лендинг для конкурса, организованного компанией "Детский Мир"',
      'Portfolio-5-text':
        'Коммерческий проект. Лендинг для конкурса, успешно проведенного компанией "Детский Мир". В рамках конкурса участники проходили тест, результаты которого позволяли получить фрагменты изображения. Эти фрагменты необходимо было собрать в единую картинку и опубликовать в социальной сети ВКонтакте.',
      'Portfolio-5-feachers-1':
        'Адаптивность для идеального отображения на разных устройствах',
      'Portfolio-5-feachers-2':
        'Кроссбраузерность для стабильной работы во всех основных браузерах',
      'Portfolio-5-feachers-3':
        'Высокая производительность благодаря оптимизации загрузки и изображений',
      'Portfolio-5-feachers-4':
        'Возможность поделиться в соцсетях полученной картинкой',

      'Portfolio-6-title': 'Telegram bot Mr.Doors',
      'Portfolio-6-description':
        'Telegram-бот, разработанный для компании Mr.Doors',
      'Portfolio-6-text':
        'Коммерческий проект. Telegram-бот для компании Mr.Doors, предназначенный для автоматизации сбора заявок.',
      'Portfolio-6-feachers-1':
        'Все поступающие заявки автоматически записываются в базу данных и одновременно пересылаются на указанный электронный адрес',
      'Portfolio-6-feachers-2': 'В боте интегрирована Google Analytics',
      'Portfolio-6-feachers-3':
        'В боте используется Telegram Web Apps для более удобного сбора данных',
    },
  },
  EN: {
    translation: {
      BykovArtem: 'Bykov Artem',
      About: 'About',
      Skills: 'Skills',
      Experience: 'Experience',
      Competence: 'Competence',
      Portfolio: 'Portfolio',
      Contact: 'Contacts',
      'My strengths':
        'My strengths: time management, responsibility and solving non-standard tasks',
      'These are the qualities ...':
        'These are the qualities that every manager has noted when working with me',
      Now: 'Now',
      Value: 'Value',
      Future: 'In Future',
      'I live in Spain ...':
        'I reside in Spain and have a work permit. Currently, I am seeking remote work opportunities in Europe with prospects for professional development',
      'In work, I appreciate ...':
        'In my professional activities, I value structure, effective communication, and clear organization of the work process, where each team member has a clear understanding of their tasks and responsibilities',
      'I will continue to deepen my knowledge ...':
        "I plan to further develop my competencies in front-end development and actively participate in the creation of unique products. My goal is to find a job where I can not only improve my skills but also make a significant contribution to the team's success",
      'I like to travel, swim ...':
        'I enjoy traveling, swimming, watching movies with intriguing plots, socializing with friends, and solving puzzles',
      'Download CV': 'Download CV',
      'Main tasks': 'Main tasks',
      'My works results': "My work's results",
      MGCom: 'MGCom, Russia, Moscow',
      'MGCom experience': 'Aug 2021 - Present (2+ years)',
      'MGCom_main-1':
        'Landing pages creation with adaptive and cross-browser layout',
      'MGCom_main-2': 'Page loading speed optimization',
      'MGCom_main-3': 'Creating and changing the layout of site components',
      'MGCom_main-4': 'Creating and modifying websites on Tilda',
      'MGCom_main-5': 'Creation of telegram bots',
      'MGCom_result-1': 'Increased the number of won tenders',
      'MGCom_result-2': 'Helped to open a new direction for attracting clients',
      ADVIGEN: 'ADVIGEN Digital, Russia, St. Petersburg',
      'ADVIGEN experience': 'Mar 2021 - Jul 2021 (5 months)',
      'ADVIGEN_main-1':
        'Adaptive and cross-browser layout of landing pages and site pages on CMS Bitrix',
      'ADVIGEN_main-2':
        'Creating and changing the layout of the site components on the Bitrix CMS',
      'ADVIGEN_main-3':
        'Checking sites by SEO criteria so that the site si higher in the search results',
      'ADVIGEN_main-4': 'Setting up analytics on websites',
      'ADVIGEN_result-1':
        'Creating a checklist for checking the site for SEO optimization, which made ti possible to speed up the process of checking sites according to standard criteria',
      AMBER: 'AMBER, Russia, St. Petersburg',
      'AMBER experience': 'Feb 2019 - Mar 2021 (2 years 1month)',
      'AMBER_main-1':
        'Analyzing the mobile version of the site and making recommendations for improving indicators',
      'AMBER_main-2': 'Setting up analytics on websites',
      'AMBER_main-3':
        'Project management and task allocation among other employees',
      'AMBER_main-4': 'Communication with clients',
      'AMBER_result-1':
        'I quickly progressed from a junior to a senior specialist',
      'AMBER_result-2': 'Took junior specialists under my leadership',
      'View Case': 'View Case',
      'See website': 'Go to website',
      'See bot': 'View the bot',
      'Contact-description':
        'If you are looking for a front-end developer, contact me:',
      Phone: 'Phone',
      'Design by': 'For design thanks',
      Stack: 'Stack',
      Tools: 'Tools',
      Feachers: 'Features',
      'Portfolio-1-title': '80 Level Cloud Experiences',
      'Portfolio-1-description': 'Landing page for 80 Level platform',
      'Portfolio-1-text':
        'A commercial project. Landing page for 80 Level platform. The platform itself is designed for content creators on Unreal Engine 5, where they can post their 3D projects.',
      'Portfolio-1-feachers-1':
        'Responsiveness for perfect display on different devices',
      'Portfolio-1-feachers-2':
        'Cross-browser compatibility for stable operation in all major browsers',
      'Portfolio-1-feachers-3':
        'High performance thanks to optimization of loading and images',
      'Portfolio-1-feachers-4':
        'Integration of Metaframe into the project was carried out using React and client API',

      'Portfolio-2-title': '80 Level Metasites Challenge',
      'Portfolio-2-description': 'Landing page for 80 Level platform',
      'Portfolio-2-text':
        'A commercial project. Landing page for 80 Level platform, aimed at gathering applications for a competition. The platform is designed for content creators on Unreal Engine 5, where they can showcase their 3D projects.',
      'Portfolio-2-feachers-1':
        'Responsiveness for perfect display on various devices',
      'Portfolio-2-feachers-2':
        'Cross-browser compatibility for stable operation across all major browsers',
      'Portfolio-2-feachers-3':
        'High performance due to optimization of loading and images',
      'Portfolio-2-feachers-4':
        'Integration of Metaframe into the project was achieved using React and client API',

      'Portfolio-3-title': 'Marvel Information Portal',
      'Portfolio-3-description':
        'An information portal about Marvel heroes and comics',
      'Portfolio-3-text':
        'This pet-project was created to improve React skills and to learn tools like Axios, MUI, styled-components, as well as to gain in-depth understanding of API interaction.',
      'Portfolio-3-feachers-1':
        'A loading indicator is displayed while data is being fetched from the server',
      'Portfolio-3-feachers-2':
        'Handling of incorrect server responses with the display of appropriate placeholders',
      'Portfolio-3-feachers-3':
        'Dynamic content loading as the page is scrolled',

      'Portfolio-4-title': 'Weather Map',
      'Portfolio-4-description':
        'An interactive weather map that allows real-time tracking of weather conditions worldwide',
      'Portfolio-4-text':
        'This pet-project was developed to enhance React skills, explore the functionalities of Mapbox, and delve deep into API interactions. The Mapbox interface enables the retrieval of coordinates with a map click, and weather information at the selected location is displayed using the Weather API.',
      'Portfolio-4-feachers-1':
        'A loading indicator is displayed while data is being fetched from the server',

      'Portfolio-5-title': 'Vacation in Dubai',
      'Portfolio-5-description':
        "Landing page for a contest organized by 'Detsky Mir' company",
      'Portfolio-5-text':
        "Commercial project. A landing page for a contest successfully held by 'Detsky Mir' company. During the contest, participants went through a quiz, the results of which allowed them to receive image fragments. These fragments had to be assembled into a complete picture and published on the VKontakte social network.",
      'Portfolio-5-feachers-1':
        'Responsiveness for perfect display on various devices',
      'Portfolio-5-feachers-2':
        'Cross-browser compatibility for stable operation in all major browsers',
      'Portfolio-5-feachers-3':
        'High performance due to optimization of loading and images',
      'Portfolio-5-feachers-4':
        'Ability to share the obtained picture on social networks',

      'Portfolio-6-title': 'Telegram Bot Mr.Doors',
      'Portfolio-6-description':
        'A Telegram bot developed for Mr.Doors company',
      'Portfolio-6-text':
        'Commercial project. A Telegram bot designed for Mr.Doors, aimed at automating the collection of applications.',
      'Portfolio-6-feachers-1':
        'All incoming applications are automatically recorded in the database and simultaneously forwarded to a specified email address',
      'Portfolio-6-feachers-2': 'Google Analytics is integrated into the bot',
      'Portfolio-6-feachers-3':
        'The bot utilizes Telegram Web Apps for more convenient data collection',
    },
  },
  ES: {
    translation: {
      BykovArtem: 'Artem Bykov',
      About: 'Sobre mí',
      Skills: 'Habilidades',
      Experience: 'Experiencia',
      Competence: 'Competencias',
      Portfolio: 'Portafolio',
      Contact: 'Contactos',
      'My strengths':
        'Puntos fuertes: gestión del tiempo, responsabilidad y resolución de tareas no convencionales',
      'These are the qualities ...':
        'Cualidades que cada gerente notó al trabajar conmigo',
      Now: 'Actualmente',
      Value: 'Valores',
      Future: 'En el futuro',
      'At free time': 'En mi tiempo libre',
      'I live in Spain ...':
        'Resido en España y tengo permiso de trabajo. Actualmente, busco oportunidades de trabajo remoto en Europa con perspectivas de desarrollo profesional',
      'In work, I appreciate ...':
        'En mi actividad profesional, valoro la estructuración, la comunicación efectiva y una organización clara del proceso laboral, donde cada integrante del equipo tiene una comprensión clara de sus tareas y responsabilidades',
      'I will continue to deepen my knowledge ...':
        'Planeo seguir desarrollando mis competencias en el desarrollo front-end y participar activamente en la creación de productos únicos. Mi objetivo es encontrar un trabajo donde no solo pueda mejorar mis habilidades, sino también contribuir significativamente al éxito del equipo',
      'I like to travel, swim ...':
        'Me gusta viajar, nadar, ver películas con tramas intrigantes, socializar con amigos y resolver rompecabezas',
      'Download CV': 'Descargar CV',
      'Main tasks': 'Tareas principales',
      'My works results': 'Resultados de mi trabajo',
      MGCom: 'MGCom, Rusia, Moscú',
      'MGCom experience': 'Agosto 2021 — Presente (2,5+ años)',
      'MGCom_main-1':
        'Diseño adaptativo y compatible con varios navegadores de páginas de aterrizaje y páginas web',
      'MGCom_main-2': 'Optimización de la velocidad de carga del sitio web',
      'MGCom_main-3': 'Creación y modificación de componentes del sitio web',
      'MGCom_main-4': 'Creación y modificación de sitios web en Tilda',
      'MGCom_main-5': 'Creación de bots de telegram',
      'MGCom_result-1': 'Aumenté el número de licitaciones ganadas',
      'MGCom_result-2': 'Ayudé a abrir nuevas direcciones para atraer clientes',
      ADVIGEN: 'ADVIGEN Digital, Rusia, San Petersburgo',
      'ADVIGEN experience': 'Marzo 2021 — Julio 2021 (5 meses)',
      'ADVIGEN_main-1':
        'Diseño adaptativo y compatible con varios navegadores de páginas de aterrizaje y páginas web en CMS Bitrix',
      'ADVIGEN_main-2':
        'Creación y modificación del diseño y componentes del sitio web en CMS Bitrix',
      'ADVIGEN_main-3': 'Revisión de sitios web según criterios SEO',
      'ADVIGEN_main-4': 'Configuración de análisis en sitios web',
      'ADVIGEN_result-1':
        'Creación de una lista de verificación para la revisión de optimización SEO del sitio web, lo que aceleró el proceso de revisión de sitios web según criterios estándar',
      AMBER: 'AMBER, Rusia, San Petersburgo',
      'AMBER experience': 'Febrero 2019 — Marzo 2021 (2 años 1 mes)',
      'AMBER_main-1':
        'Análisis de la versión móvil del sitio web y elaboración de recomendaciones para mejorar los indicadores',
      'AMBER_main-2': 'Configuración de análisis en sitios web',
      'AMBER_main-3':
        'Gestión de proyectos y distribución de tareas entre otros empleados',
      'AMBER_main-4': 'Comunicación con clientes',
      'AMBER_result-1': 'Avancé rápidamente de especialista junior a senior',
      'AMBER_result-2': 'Tomé bajo mi liderazgo a especialistas junior',
      'View Case': 'Ver caso',
      'See website': 'Visitar sitio web',
      'See bot': 'Ver el bot',
      'Contact-description':
        'Si buscas un desarrollador front-end, no dudes en contactarme:',
      Phone: 'Teléfono',
      Stack: 'Stack Tecnológico',
      Tools: 'Herramientas',
      Feachers: 'Características',
      'Design by': 'Diseñado por',
      'Portfolio-1-title': '80 Level Cloud Experiences',
      'Portfolio-1-description':
        'Página de destino para la plataforma 80 Level',
      'Portfolio-1-text':
        'Un proyecto comercial. Página de destino para la plataforma 80 Level. La propia plataforma está diseñada para creadores de contenido en Unreal Engine 5, donde pueden publicar sus proyectos 3D.',
      'Portfolio-1-feachers-1':
        'Adaptabilidad para una visualización perfecta en diferentes dispositivos',
      'Portfolio-1-feachers-2':
        'Compatibilidad entre navegadores para un funcionamiento estable en todos los navegadores principales',
      'Portfolio-1-feachers-3':
        'Alto rendimiento gracias a la optimización de la carga y las imágenes',
      'Portfolio-1-feachers-4':
        'La integración de Metaframe en el proyecto se realizó utilizando React y la API del cliente',

      'Portfolio-2-title': '80 Level Metasites Challenge',
      'Portfolio-2-description':
        'Página de aterrizaje para la plataforma 80 Level',
      'Portfolio-2-text':
        'Un proyecto comercial. Página de aterrizaje para la plataforma 80 Level, para recopilar solicitudes para un concurso. La plataforma está diseñada para creadores de contenido en Unreal Engine 5, donde pueden publicar sus proyectos 3D.',
      'Portfolio-2-feachers-1':
        'Adaptabilidad para una visualización perfecta en diversos dispositivos',
      'Portfolio-2-feachers-2':
        'Compatibilidad con varios navegadores para un funcionamiento estable en todos los principales',
      'Portfolio-2-feachers-3':
        'Alto rendimiento gracias a la optimización de la carga y las imágenes',
      'Portfolio-2-feachers-4':
        'La integración de Metaframe en el proyecto se logró mediante el uso de React y la API del cliente',
      'Portfolio-3-title': 'Portal de Información de Marvel',
      'Portfolio-3-description':
        'Un portal informativo sobre los héroes y cómics de Marvel',
      'Portfolio-3-text':
        'Este proyecto personal fue creado para mejorar habilidades en React y aprender herramientas como Axios, MUI, styled-components, además de profundizar en la interacción con API.',
      'Portfolio-3-feachers-1':
        'Se muestra un indicador de carga mientras se obtienen datos del servidor',
      'Portfolio-3-feachers-2':
        'Manejo de respuestas incorrectas del servidor con la visualización de placeholders apropiados',
      'Portfolio-3-feachers-3':
        'Carga dinámica de contenido al desplazar la página',

      'Portfolio-4-title': 'Mapa del Tiempo',
      'Portfolio-4-description':
        'Un mapa interactivo del tiempo que permite el seguimiento en tiempo real de las condiciones meteorológicas en todo el mundo',
      'Portfolio-4-text':
        'Este proyecto personal fue desarrollado para mejorar habilidades en React, explorar las funcionalidades de Mapbox y profundizar en la interacción con las API. La interfaz de Mapbox ofrece la capacidad de obtener coordenadas al hacer clic en el mapa, y mediante el uso de la API de Weather, se implementó la función de mostrar el tiempo meteorológico en el punto seleccionado.',
      'Portfolio-4-feachers-1':
        'Se muestra un indicador de carga mientras se obtienen datos del servidor',

      'Portfolio-5-title': 'Vacaciones en Dubái',
      'Portfolio-5-description':
        "Página de aterrizaje para un concurso organizado por la compañía 'Detsky Mir'",
      'Portfolio-5-text':
        "Proyecto comercial. Página de aterrizaje para un concurso exitosamente llevado a cabo por la compañía 'Detsky Mir'. En el marco del concurso, los participantes realizaban un test, cuyos resultados les permitían obtener fragmentos de una imagen. Estos fragmentos debían ser ensamblados en una imagen completa y publicados en la red social VKontakte.",
      'Portfolio-5-feachers-1':
        'Adaptabilidad para una visualización perfecta en diversos dispositivos',
      'Portfolio-5-feachers-2':
        'Compatibilidad con varios navegadores para un funcionamiento estable en todos los principales',
      'Portfolio-5-feachers-3':
        'Alto rendimiento gracias a la optimización de la carga y las imágenes',
      'Portfolio-5-feachers-4':
        'Posibilidad de compartir la imagen obtenida en redes sociales',

      'Portfolio-6-title': 'Bot de Telegram para Mr.Doors',
      'Portfolio-6-description':
        'Un bot de Telegram desarrollado para la empresa Mr.Doors',
      'Portfolio-6-text':
        'Proyecto comercial. Un bot de Telegram diseñado para Mr.Doors, destinado a automatizar la recolección de solicitudes.',
      'Portfolio-6-feachers-1':
        'Todas las solicitudes recibidas se registran automáticamente en la base de datos y se reenvían simultáneamente a una dirección de correo electrónico especificada',
      'Portfolio-6-feachers-2': 'Google Analytics está integrado en el bot',
      'Portfolio-6-feachers-3':
        'El bot utiliza Telegram Web Apps para una recolección de datos más conveniente',
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('lng') ? localStorage.getItem('lng') : 'EN', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
