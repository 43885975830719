import { useTranslation } from 'react-i18next';
import './About.scss';

function About() {
  const { t, i18n } = useTranslation();

  const hrefCV = () => {
    switch (i18n.language) {
      case 'RU': 
        return '/cv/CV_Artem_Bykov_RU.pdf';
      case 'EN':
        return '/cv/CV_Artem_Bykov_EN.pdf';
      default: 
        return '/cv/CV_Artem_Bykov_EN.pdf';
    }
  }

  return (
    <div className='about' id='about'>
      <div className='container'>
        <div className='about__block'>
          <h2 className='about__title'>{t('My strengths')}</h2>
          <p className='about__description'>
            {t('These are the qualities ...')}
          </p>
          <ul className='about__list'>
            <li className='about__item'>
              <p className='about__item-title about__item-title--time'>
                {t('Now')}
              </p>
              <p className='about__item-description'>
                {t('I live in Spain ...')}
              </p>
            </li>
            <li className='about__item'>
              <p className='about__item-title about__item-title--value'>
                {t('Value')}
              </p>
              <p className='about__item-description'>
                {t('In work, I appreciate ...')}
              </p>
            </li>
            <li className='about__item'>
              <p className='about__item-title about__item-title--future'>
                {t('Future')}
              </p>
              <p className='about__item-description'>
                {t('I will continue to deepen my knowledge ...')}
              </p>
            </li>
            <li className='about__item'>
              <p className='about__item-title about__item-title--free'>
                {t('At free time')}
              </p>
              <p className='about__item-description'>
                {t('I like to travel, swim ...')}
              </p>
            </li>
          </ul>
          <a href={hrefCV()} target="_blank"  className='btn' rel="noreferrer">
            {t('Download CV')}
          </a>
        </div>
      </div>
    </div>
  );
}

export default About;
